<template>
  <div class="container-fluid" :class="{
    'search-global-block': isActiveType === 'search-global',
    'search-search2-block': isActiveType === 'search-search2',
     'search-block-active': showSearchForm,
     'search-block-inactive': !showSearchForm,
  }" v-click-outside="hideSearch">
    <transition name="slide-fade">
      <div class="row" v-show="showSearchForm">
        <div class="col-sm-12 col-12 search-block">
          <!--<input type="text" class="form-control" placeholder="Search &amp; enter">-->
          <v-select class="global-search" @search="fetchOptions" :filterable="false" :options="options"
                    label="title"
                    height="300px"
                    :autoscroll="false"
                    autocomplete="off"
                    ref="searchLine"
                    :placeholder="!filter ? $t('start_write_for_search') : $t('start_write_for_search_by')"
                    v-model="selected"
                    @input="processSeletected"
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching && search.length >= 3">
                {{ $t('no_results') }}<em>{{ search }}</em
              >.
              </template>
              <em v-else style="opacity: 0.5">{{ $t('type_more_symbols_for_search') }}</em>
            </template>
            <template v-slot:option="option">
              <div style="margin-top: 5px; margin-bottom: 5px">
                <div v-if="option.type === 'ont_ident'">
                  <div class="row">
                    <div class="col-1 d-none d-md-block"><i class="mdi mdi-24px mdi-router"></i></div>
                    <div class="col-12 col-mini col-md-11 col-sm-11 col-lg-11">
                      {{ $t('ont_ident') }}: <b>{{ option.data.ident }}</b>
                      <span :class="`search-status ` + (option.data.interface.status === 'Online' ? 'search-status-online' : 'search-status-offline')">{{option.data.interface.status}}</span>
                      <br>
                      <small><i>{{ $t('interface') }}: {{ option.data.interface.name }}, {{ $t('on_device') }}
                        {{ option.data.interface.device.name }}</i>
                      </small>

                    </div>
                  </div>
                </div>
                <div v-if="option.type === 'fdb_history'">
                  <div class="row">
                    <div class="col-1 d-none d-md-block"><i class="mdi mdi-24px mdi-ethernet"></i></div>
                    <div class="col-12 col-mini col-md-11 col-sm-11 col-lg-11"
                         style="word-wrap: break-word !important;">
                      {{ $t('mac_in_fdb') }}: <b>{{ option.data.mac_address }} ({{ option.data.vlan_id }})</b>
                      <span :class="`search-status ` + (option.data.is_active ? 'search-status-online' : 'search-status-offline')">{{ option.data.is_active ? $t('active') : $t('not_active')}}</span>
                      <br>
                      <small><i>{{ $t('interface') }}: {{ option.data.interface.name }}, {{ $t('on_device') }}
                        {{ option.data.interface.device.name }}</i></small>
                    </div>
                  </div>
                </div>
                <div v-if="option.type === 'device'">
                  <div class="row">
                    <div class="col-1 d-none d-md-block"><i class="mdi mdi-24px mdi-server-network"></i></div>
                    <div class="col-12 col-mini col-md-11 col-sm-11 col-lg-11"
                         style="word-wrap: break-word !important;">
                      {{ $t('device') }}: <b>{{ option.data.name }} ({{ option.data.ip }})</b><br>
                      <i>{{ $t('model') }}: {{ option.data.model.name }}</i>
                    </div>
                  </div>
                </div>
                <div v-if="option.type === 'interface'">
                  <div class="row">
                    <div class="col-1 d-none d-md-block"><i class="mdi mdi-24px mdi-ethernet-cable"></i></div>
                    <div class="col-12 col-mini col-md-11 col-sm-11 col-lg-11"
                         style="word-wrap: break-word !important;">
                      {{ $t('interface') }}: <b>{{ option.data.name }} ({{ option.data.type }})</b>
                      <span :class="`search-status ` + (['Up', 'Online'].includes(option.data.status) ? 'search-status-online' : 'search-status-offline')">{{option.data.status}}</span>
                      <br>
                      <small><i>{{ $t('on_device') }} {{ option.data.device.name }}
                        ({{ option.data.device.ip }})</i></small>
                    </div>
                  </div>
                </div>
                <div v-if="option.type === 'description'">
                  <div class="row">
                    <div class="col-1 d-none d-md-block"><i class="mdi mdi-24px mdi-comment"></i></div>
                    <div class="col-12 col-mini col-md-11 col-sm-11 col-lg-11"
                         style="word-wrap: break-word !important;">
                      {{ $t('description') }}: <b>{{ option.data.description }}</b><span :class="`search-status ` + (['Up', 'Online'].includes(option.data.status) ? 'search-status-online' : 'search-status-offline')">{{option.data.status}}</span>
                      <br>
                      <small><i>{{ $t('interface') }}: {{ option.data.name }} ({{ option.data.type }})
                        {{ $t('on_device') }} {{ option.data.device.name }}
                        ({{ option.data.device.ip }})</i></small>
                    </div>
                  </div>
                </div>
                <div v-if="option.type === 'agreement'">
                  <div class="row">
                    <div class="col-1 d-none d-md-block"><i class="mdi mdi-24px mdi-comment"></i></div>
                    <div class="col-12 col-mini col-md-11 col-sm-11 col-lg-11"
                         style="word-wrap: break-word !important;">
                      {{ $t('abonent') }}: <b>{{ option.data.agreement }}</b><span :class="`search-status ` + (['Up', 'Online'].includes(option.data.status) ? 'search-status-online' : 'search-status-offline')">{{option.data.status}}</span><br>
                      <small><i>{{ $t('interface') }}: {{ option.data.name }} ({{ option.data.type }})
                        {{ $t('on_device') }} {{ option.data.device.name }}
                        ({{ option.data.device.ip }})</i></small>
                    </div>
                  </div>
                </div>
                <div v-if="option.type === 'tags'">
                  <div class="row">
                    <div class="col-1 d-none d-md-block"><i class="mdi mdi-24px mdi-tag"></i></div>
                    <div class="col-12 col-mini col-md-11 col-sm-11 col-lg-11"
                         style="word-wrap: break-word !important;">
                      {{ $t('tag') }}: <b>#{{ option.data.tags }}</b><span :class="`search-status ` + (['Up', 'Online'].includes(option.data.interface.status) ? 'search-status-online' : 'search-status-offline')">{{option.data.interface.status}}</span><br>
                      <small><i>{{ $t('interface') }}: {{ option.data.interface.name }} ({{ option.data.interface.type }})
                        {{ $t('on_device') }} {{ option.data.interface.device.name }}
                        ({{ option.data.interface.device.ip }})</i></small>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </v-select>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div class="row" v-show="showSearchBtn">
        <div class="col-sm-4 col-4" style="padding-top: 2px">
          <a class="nav-link search-btn" @click="showSearch()" style="cursor: pointer;">
            <i  class="mdi mdi-24px mdi-magnify"></i>
          </a>
        </div>
        <div class="col-sm-4 col-4" style="padding-top: 2px" v-if="$config.search2.enabled">
          <a class="nav-link search-btn" @click="showSearch($config.search2.filter)" style="cursor: pointer;">
            <i class="mdi mdi-24px mdi-account-search"></i>
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    isActiveType: {
      type: String,
      default: 'search-global',
    }
  },
  data() {
    return {
      showSearchForm: false,
      showSearchBtn: true,
      options: [],
      selected: null,
      width: null,
      filter: '',
    }
  },
  mounted() {
    this.width = window.innerWidth
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  watch: {
    isActiveType: {
      handler(value) {
        if(value === 'search-global') {
          this.filter = '';
        }
        else if(value === 'search-search2') {
          this.filter = this.$config.search2.filter;
        }
      },
    },
    width: {
      handler(w) {
        if(w && w < 765) {
          this.showSearch()
        }
      },
    }
  },
  methods: {
    onResize() {
      this.width = window.innerWidth
      console.log("Width: ", this.width)
    },
    processSeletected(selected = null) {
      if (selected === null) {
        selected = this.selected
      }
      switch (selected.type) {
        case 'interface':
          this.$router.push({
            name: 'device_iface_dashboard',
            params: {
              id: selected.data.device.id,
              interface: selected.data.bind_key
            }
          })
          break;
        case 'description':
        case 'agreement':
          this.$router.push({
            name: 'device_iface_dashboard',
            params: {
              id: selected.data.device.id,
              interface: selected.data.bind_key
            }
          })
          break;
        case 'fdb_history':
          this.$router.push({
            name: 'device_iface_dashboard',
            params: {
              id: selected.data.interface.device.id,
              interface: selected.data.interface.bind_key
            }
          })

          break;
        case 'ont_ident':
          this.$router.push({
            name: 'device_iface_dashboard',
            params: {
              id: selected.data.interface.device.id,
              interface: selected.data.interface.bind_key
            }
          });
          break;
        case 'tags':
          this.$router.push({
            name: 'device_iface_dashboard',
            params: {
              id: selected.data.interface.device.id,
              interface: selected.data.interface.bind_key
            }
          });
          break;
        case 'device':
          this.$router.push({
            name: 'device_dashboard',
            params: {
              id: selected.data.id
            }
          })
          break;
      }
      this.hideSearch()
      this.options = []
    },
    showSearch(filter = '') {
      this.filter = filter
      this.showSearchBtn = false
      setTimeout(() => {
        this.showSearchForm = true
        setTimeout(() => {
          this.$nextTick(() => {
            const input = this.$refs.searchLine.$el.querySelector('input')
            input.focus()
          })
        }, 100)
      }, 100)
    },
    hideSearch() {
      this.options = []
      this.selected = null;
      if(this.width > 768) {
        this.showSearchForm = false
        setTimeout(() => {
          this.showSearchBtn = true
        }, 400)
      }
    },
    fetchOptions(search, loading) {
      if (search.length < 1) {
        return null
      }
      loading(true)

      this.$api.post('/portal/search', {query: search, filter: this.filter}).then(r => {
        let data = []
        r.data.forEach(d => {
          let title = ''
          switch (d.type) {
            case 'fdb_history':
              title = '';
              break;
            case 'device':
              title = '';
              break;
            case 'ont_ident':
              title = '';
              break;
            case 'interfaces':
              title = '';
              break;
          }
          d.title = title
          data.push(d)
          // this.selected = null

        })
        this.options = data
        loading(false)
      })
    },
  },
}
</script>

<style scoped>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .5s ease;
}

.slide-fade-leave-active {
  transition: all .3s ease;
}

.fade-leave-active {
  transition: all 0s ease;
}

.fade-enter-active {
  transition: all 0.5s ease;
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}


.search-status {
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 10px;
  color: #FAFAFA;
}

.search-status-online {
  margin-left: 5px;
  margin-right: 5px;
  background: rgba(0, 116, 11, 1);
}

.search-status-offline {
  margin-left: 5px;
  margin-right: 5px;
  background: darkred;
}


</style>
<style>


.global-search .vs__search::placeholder,
.global-search .vs__dropdown-toggle,
.global-search .vs__dropdown-menu {
  background: #EFEFEF;
  border: none;
  border-radius: 1px;
  color: #1e243b;
}

.global-search .vs__dropdown-toggle {
  height: 35px;
}

.global-search .vs__clear,
.global-search .vs__open-indicator {
  fill: #1c2031;

}

.search-block {
  min-width: 280px;
  max-width: 550px;
  width: 100%;

  @media (min-width: 360px) {
    min-width: 330px;
  }

  @media (min-width: 528px) {
    min-width: 400px;
  }

  @media (min-width: 768px) {
    min-width: 490px;
  }
}

</style>
