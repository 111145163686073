<template>
  <div class="row">
    <div class="col-12">
      <div ref="networkContainer" class="network-container"></div>
        </div>
        <div class="col-12">
          <Preloader v-if="loading" ></Preloader>
        </div>
  </div>
</template>


<script>

import { DataSet, Network } from "vis-network/standalone";
import Preloader from "@/components/Preloader.vue";

export default {
  components: {Preloader},
  data() {
    return {
      loading: false,
      network: null,
      nodes: null,
      edges: null,
      nodeIds: [],
    };
  },
  mounted() {
    this.initializeNetwork();
  },
  methods: {
    async initializeNetwork() {
      // Данные узлов

      let nodes  = await this.loadDevices()
      this.nodes = new  DataSet(nodes);


      // Данные соединений
      let links = await this.loadLinks()
      this.edges = new  DataSet(links);

      const data = {
        nodes: this.nodes,
        edges: this.edges,
      };

      const options = {
        interaction: { hover: true },
        nodes: {
          shape: "dot",
          font: { color: "#343434", size: 14 },
        },
        edges: {
          font: {  },
          smooth: { type: "dynamic" },
        },
        physics: {
          enabled: true,
          solver: "forceAtlas2Based",
          stabilization: {
            enabled: false,
            iterations: 200, // Снижение количества итераций для быстрой стабилизации
            updateInterval: 10,
          },
        },
      };

      // Инициализация сети
      this.network = new Network(this.$refs.networkContainer, data, options);

      this.getPingerInfo()
      this.changeSizes()

      // Обработчики событий
      this.network.on("click", this.handleNodeClick);
    },
    handleNodeClick(params) {
      if (params.nodes.length > 0) {
        const nodeId = params.nodes[0];
        const nodeData = this.nodes.get(nodeId);
        alert(`Clicked on node: ${nodeData.label}`);
      } else if (params.edges.length > 0) {
        const edgeId = params.edges[0];
        const edgeData = this.edges.get(edgeId);
        alert(`Clicked on link: ${edgeData.label}`);
      }
    },
    changeNodeColor(nodeId, color) {
      this.nodes.update({ id: nodeId, color: { background: color, border: "gray" } });
      this.edges.forEach((edge) => {
        if (edge.from === nodeId || edge.to === nodeId) {
          this.edges.update({ id: edge.id, color: { color: color }, width: 2 });
        }
      });
    },
    adjustNodeSizeByLinks(nodeId) {
      const connectedEdges = this.edges.get({
        filter: (edge) => edge.from === nodeId || edge.to === nodeId,
      });
      const newSize = 15 + connectedEdges.length * 2;
      this.nodes.update({ id: nodeId, size: newSize });
    },
    async loadDevices() {
      let nodes = []
      await  this.$api.get("/device?limit=99999").then(r => {
           r.data.forEach(dev => {
             nodes.push( { id: dev.id, label: dev.ip, shape: "dot", color: { background: "#848484" }, size: 15 })
           })
        })
      return nodes
    },
    async loadLinks() {
      let edges = []
      this.nodeIds = []
      await  this.$api.get("/component/links").then(r => {
           r.data.forEach(link => {
             let iface = 'N/A'
             if(link.dest_iface != null) {
               iface = link.dest_iface.name
             }
             let srcIface = 'N/A'
             if(link.src_iface != null) {
               srcIface = link.src_iface.name
             }
             this.nodeIds.push(link.src_device.id)
             edges.push({ from: link.src_device.id, to: link.dest_device.id, label: `${srcIface} -> ${iface}`, color: { color: "#848484" }, width: 1, arrows: "to", font: {size: 12}  },)
           })
        })
      return edges
    },
    getPingerInfo() {
      this.$api.get('/component/pinger/statuses').then(r => {
         r.data.forEach(i => {
             let color = i.status <= 0 ? 'darkred': 'darkgreen'
             this.changeNodeColor(i.id, color)
         })
      })
    },
    changeSizes() {
      this.nodeIds.forEach(id => {
        this.adjustNodeSizeByLinks(id)
      })
    }
  },
};
</script>

<style scoped>
.network-container {
  width: 100%;
  height: 100vh;
  border: 1px solid lightgray;
}
</style>