<template>
  <header class="topbar" data-navbarbg="skin5">
    <nav class="navbar top-navbar navbar-expand-md navbar-dark" v-click-outside="hideBlock" >
      <div class="navbar-header" data-logobg="skin5">
        <!-- This is for the sidebar toggle which is visible on mobile only -->
        <a class="nav-toggler waves-effect waves-light d-block d-md-none" href="javascript:void(0)" @click="hideBlock"><i  id="ti-menu-toggler" class="ti-menu ti-close"></i></a>

        <NavBarBrand />
<!--        <a class="topbartoggler d-block d-md-none waves-effect waves-light"-->
<!--           href="javascript:void(0)"-->
<!--           @click="togleBlock" style="margin-bottom: -3px"-->
<!--        ><i class="ti-search"></i><br>-->
<!--        </a>-->
        <div style="display: flex;
          align-items: center;
          justify-content: center;"
        >
          <a class="topbartoggler d-block d-md-none waves-effect waves-light"
             href="javascript:void(0)"
             @click="togleBlock('search-global')"
          ><i class="mdi mdi-24px mdi-magnify"></i><br>
          </a>
          <a class="topbartoggler d-block d-md-none waves-effect waves-light"
             href="javascript:void(0)"
             @click="togleBlock('search-search2')"
             v-if="$config.search2.enabled"
          ><i class="mdi mdi-24px mdi-account-search"></i>
          </a>
        </div>
      </div>
      <!-- ============================================================== -->
      <!-- End Logo -->
      <!-- ============================================================== -->
      <div :class="navBarClasses" id="navbarSupportedContent" >
        <!-- ============================================================== -->
        <!-- toggle and nav items -->
        <!-- ============================================================== -->
        <LeftNavBar :isActiveType="isActiveType" />
        <!-- ============================================================== -->
        <!-- Right side toggle and nav items -->
        <!-- ============================================================== -->
        <RightNavBar />
      </div>
    </nav>
  </header>
</template>

<script>
import LeftNavBar from "./TopBar/LeftNavBar";
import RightNavBar from "./TopBar/RightNavBar";
import NavBarBrand from "./TopBar/NavBarBrand";

export default {
  data() {
    return {
      navBarClasses: 'navbar-collapse collapse',
      isActive: false,
      isActiveType: '',
    }
  },
  watch: {
    "$route": {
      handler() {
        this.hideBlock()
      } ,
      deep: true
    }
  },
  methods: {
    hideBlock() {
      this.navBarClasses = 'navbar-collapse collapse active'
      this.isActive = false
    },
    showBlock(type) {
      this.navBarClasses = 'navbar-collapse collapse active show'+' '+type
      this.isActive = true
    },
    togleBlock(type = 'search-global') {
      if(this.isActive) {
        this.hideBlock()

        if(this.isActiveType !== type) {
          this.isActiveType = type;
          this.showBlock(type)
        }
      } else {
        this.isActiveType = type;
        this.showBlock(type)
      }
    }
  },
  components: {NavBarBrand, RightNavBar, LeftNavBar}
}
</script>

<style>
.search-global .search-global-block {
  display: block;
}

.search-search2 .search-search2-block {
  display: block;
}

@media (max-width: 768px) {
  .search-global-block, .search-search2-block {
    margin-top: 15px;
    margin-left: 15px;
  }

  .search-global-block, .search-search2-block {
    display: none;
  }

  .search-global .search-global-block {
    display: block;
  }

  .search-search2 .search-search2-block {
    display: block;
  }
}
</style>
