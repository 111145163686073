import menus from "./menus";
import en from './../locales/en'
import ru from './../locales/ru'
import ua from './../locales/ua'
import en_wl from './../locales/wl/en'
import ru_wl from './../locales/wl/ru'
import ua_wl from './../locales/wl/ua'
import device_dashboards from "../views/DeviceInfo/DeviceDashboard/device_dashboards";
import versions from "./versions"

export default {
    apiBaseUrl: "/api/v1",
    version:  versions,
    locale: {
        default_locale: 'en',
        locales: {
            en: en,
            ru: ru,
            ua: ua,
        },
        localesWL: {
            en: en_wl,
            ru: ru_wl,
            ua: ua_wl,
        },
        supportedLocales: {},
    },
    map: {
        coordinates: {
            lat: 0,
            lon: 0,
        }
    },
    name: 'DMS',
    user: {
      id: -1,
      name: 'Quest',
      group: {
          id: -1,
          name: 'Quest',
      },
      login: 'quest'
    },
    zoom: 1.0,
    homepage: '/',
    menu: menus,
    components: {},
    enabledComponents: [],
    defaultRolePermissions: [],
    isComponentEnabled(componentName) {
        return this.enabledComponents.indexOf(componentName) !== -1
    },
    deviceComponents: device_dashboards,
    isWhiteLabel: false,
    isDemoMode: false,
    search2: {
        enabled: false,
        filter: 'agreement'
    }
}
