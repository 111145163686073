export default {
    sys_config: {
        tabs: {
            system_configuration: "Конфигурация системы",
            components: "Компоненты",
            schedule: "Планировщик",
            system_info: "Системная информация",
        },
        groups: {
            auto_topology: "Автоматическая топология",
            notifications: "Уведомления",
            web_panel: "Веб-панель",
            system: "Система",
            security: "Безопасность",
            proxy: "Прокси",
            switcher_core: "Работа с устройствами",
            all_ok_billing: "Component: AllOkBilling",
            oxidized: "Бекапы конфигов (Oxidized)",
            nodeny_plus: "NoDeny plus",
            search_device: "Search devices",
            mikbill: "MikBill",
        },
        by_default: "по умолчанию: '{default}'",
        parameter_name: {
            AUTO_TOPOLOGY_SCHEDULE_ENABLED: "Запускать сбор автоматически",
            AUTO_TOPOLOGY_THREADS: "Количество потоков",
            SEARCH2_FILTER: "Дополнительный поиск по",
            SEARCH2_ENABLED: "Статус дополнительного поиска",
            RATE_LIMITER_TIME: "Таймаут блокировки IP",
            RATE_LIMITER_ATTEMPTS: "Количество попыток",
            RATE_LIMITER_ENABLED: "Включить защиту от брутфорса",
            MIKBILL_SET_ONT_COORDINATES: "Определять координаты ОНУ",
            ALL_OK_BILLING_SERVICE_URL: "URL сервиса",
            MAP_TILE_LAYER: "Тип карты",
            SWC_ENABLE_SPLITTING: "Разделять ответ по интерфейсах",
            MIKBILL_API_ADDR: "MikBill веб-адрес",
            MIKBILL_AUTH_KEY: "Ключ API",
            MIKBILL_GLOBAL_SEARCH_FIELD: "Использовать для глобального поиска",
            WEB_ZOOM_MAIN: "Масштаб веб-интерфейса",
            WEB_ZOOM_IFRAME: "Масштаб веб-интерфейса(в iframe)",
            SWC_CHECK_ICMP_PING: "Check ICMP status",
            NODENY_URL: "NoDeny веб-адрес",
            NODENY_DATABASE_DSN: "DSN URL для подключения к БД",
            NODENY_DATABASE_USERNAME: "Имя пользователя",
            NODENY_DATABASE_PASSWORD: "Пароль пользователя",
            NODENY_COMPARISON_DESCRIPTION_REQUIRED: "Описание должно совпадать с именем",
            NODENY_COMPARISON_CHECK_TOPOLOGY: "Проверять топологию",
            EXTERNAL_HTTP_ADDRESS: "Веб-адрес системы",
            OXIDIZED_URL: "Oxidized URL",
            OXIDIZED_THREADS: "Количество потоков",
            OXIDIZED_INTERVAL: "Интервал бекапа",
            OXIDIZED_TIMEOUT: "Таймаут",
            POLLER_DO_NOT_CLEAR_INTERFACES: "Не очищать интерфейсы",
            SWC_CONSOLE_WAIT_BYTE_SEC: "Таймаут потоку байтов",
            SWC_SNMP_VERSION: "SNMP-версия",
            NOTIFICATIONS_CHECK_PREVIOUS_MESSAGE: "Проверять предыдущие сообщения",
            DEFAULT_LANGUAGE: 'Язык по-умолчанию',
            APP_NAME: 'Имя агента',
            LOGS_RETURN_RESULTS_LIMIT: 'Лимит возвращаемых записей',
            MAP_COORDINATES: 'Координаты карты за-умолчанием',
            PROMETHEUS_RETENTION_TIME: 'Время жизни графиков',
            LOG_LEVEL: 'Уровень логов',
            RR_NUM_WORKERS: 'Количество потоков веб-сервера',
            POLLER_IGNORE_DOWN: 'Игнорировать упавшие хосты',
            POLLER_COUNT_PROCS: 'Количество опросчиков',
            API_KEY_EXPIRATION: 'Время жизни сессии',
            TRUSTED_HOST_NETWORK_LIST: 'Список доверенных сетей',
            PROXY_ENABLED: 'Прокси включен',
            PROXY_REAL_IP_HEADER: 'Заголовок реального IP',
            SWC_CONSOLE_CONN_TYPE: 'Тип подключения консоли',
            SWC_CONSOLE_PORT: 'Порт консоли',
            SWC_CONSOLE_TIMEOUT_SEC: 'Таймаут консоли',
            SWC_SNMP_REPEATS: 'SNMP-повторы',
            SWC_SNMP_TIMEOUT_SEC: 'SNMP-таймаут',
            SWC_SNMP_PORT: 'SNMP-порт',
            SWC_MIKROTIK_API_PORT: 'Mikrotik API-порт',
            SEARCH_DEVICE_SOURCES: 'Поиск источников',
            SEARCH_DEVICE_CHECK_ALL_SOURCES: 'Проверять все источники',
            SEARCH_DEVICE_HISTORY_ONLY_ACTIVE: 'Получить только активные',
            SEARCH_DEVICE_IGNORE_TAG_PORTS: 'Игнорировать порты тегов',
            SEARCH_DEVICE_REQUEST_CONCURRENCY: 'Количество потоков',
            SWC_CACHE_ACTUALIZE_TIMEOUT_SEC: "Тайм-аут актуализации",
        },
        parameter_description: {
            SEARCH2_FILTER: "Дополнительный поиск по указанному объекту",
            SEARCH2_ENABLED: "Будет показан дополнительный поиск в заголовке",
            RATE_LIMITER_TIME: "Время пребывания IP в черном списке в секундах",
            RATE_LIMITER_ATTEMPTS: "Количество неудачных попыток входа для отправки IP в черный список (за 10 минут)",
            RATE_LIMITER_ENABLED: "Включить защиту от перебора с помощью блокировки IP",
            ALL_OK_BILLING_SERVICE_URL: "For example - https://service.billing.com. Used for build billing links",
            MAP_TILE_LAYER: "Выбор источника карт по-умолчанию",
            SWC_ENABLE_SPLITTING: "При вызове модулей без указания интерфейса (например получение списка ОНУ или их статусов), полученный ответ будет прочитан в разрезе интерфесов и закеширован",
            MIKBILL_API_ADDR: "MikBill веб-адрес, в формате https://admin.isp",
            MIKBILL_AUTH_KEY: "Ключ API. Указывайте тот же, что указан в MikBill",
            MIKBILL_GLOBAL_SEARCH_FIELD: "Поле абонента, которое следует использовать в поиске",
            WEB_ZOOM_MAIN: "Значение по-умолчанию - 1.0. Например, что бы установить 95% - укажите 0.95. Эта опция эксперементальная, может привести к багам с отображением элементов",
            WEB_ZOOM_IFRAME: "Значение по-умолчанию - 1.0. Например, что бы установить 95% - укажите 0.95. Эта опция эксперементальная, может привести к багам с отображением элементов",
            SWC_CHECK_ICMP_PING: "Проверять состояние устройства по ICMP перед отправкой запросов",
            SWC_SNMP_PORT: '',
            EXTERNAL_HTTP_ADDRESS: "Внешний адрес веб-панели WildcoreDMS",
            NODENY_URL: "Web address to NoDeny, used for links to user cards. For example - https://billing.company.com",
            NODENY_DATABASE_DSN: "Used PDO URL format. For example - mysql:host=127.0.0.1;dbname=nodeny;charset=utf8",
            NODENY_DATABASE_USERNAME: "For example - root",
            NODENY_DATABASE_PASSWORD: "",
            NODENY_COMPARISON_DESCRIPTION_REQUIRED: "Описание должно соответствовать названию",
            NODENY_COMPARISON_CHECK_TOPOLOGY: "Проверить топологию в поиске",
            OXIDIZED_TIMEOUT: "Таймаут работы с устройством в секундах",
            OXIDIZED_URL: "URL для oxidized экземпляра. Не меняйте его, если вы используете встроенную",
            OXIDIZED_THREADS: "Максимальное количество потоков для работы с устройствами. Рекомендуется — потоки = количество ядер ЦП.",
            OXIDIZED_INTERVAL: "Интервал резервного копирования в секундах",
            POLLER_DO_NOT_CLEAR_INTERFACES: "Не очищать интерфейсы автоматически. Если опция включена - опросщик не удаляет старые интерфейсы",
            SWC_CONSOLE_WAIT_BYTE_SEC: "Тайм-аут ожидания потока данных",
            SWC_SNMP_VERSION: "SNMP version",
            NOTIFICATIONS_CHECK_PREVIOUS_MESSAGE: "Проверять предыдущее уведомления",
            MAP_COORDINATES: 'Координаты карты по умолчанию',
            LOG_LEVEL: 'Log level',
            RR_NUM_WORKERS: 'Count procs',
            POLLER_IGNORE_DOWN: 'Игнорировать лежащие хосты опросчиком',
            POLLER_COUNT_PROCS: 'Количество потоков опросчика',
            PROXY_ENABLED: 'Proxy enabled',
            PROXY_REAL_IP_HEADER: 'Real IP header',
            SWC_CONSOLE_CONN_TYPE: 'Connection type',
            SWC_CONSOLE_PORT: 'Console port',
            SWC_CONSOLE_TIMEOUT_SEC: 'Console timeout',
            PROMETHEUS_URL: "Prometheus URL",
            ALERTMANAGER_URL: "Alertmanager URL",
            PROMETHEUS_RETENTION_TIME: "Очищать историю через",
            REDIS_HOST: "Redis хост",
            REDIS_PORT: "Redis порт",
            REDIS_PASSWORD: "Redis пароль(оставить пустым, если без пароля)",
            ALLOW_TRUSTED_HOSTS: "Проверять доверенные IP-адреса",
            GROUP_DEVICES: "Группировать устройства",
            SEARCH_DEVICE_SOURCES: "Источники поиска",
            SEARCH_DEVICE_CHECK_ALL_SOURCES: "Проверять все источники",
            SEARCH_DEVICE_HISTORY_ONLY_ACTIVE: "Из истории, только активные",
            SEARCH_DEVICE_IGNORE_TAG_PORTS: "Игнорировать транзитные порты(свитчи)",
            SEARCH_DEVICE_REQUEST_CONCURRENCY: "Конкурентность запросов(чем больше значение - быстрее обработка и больше потребление памяти)",
            LOGS_RETURN_RESULTS_LIMIT: "Максимальное количество возвращаемых записей логов(действия пользователей, вызовы коллектора, вызовы устройств)",
            DEFAULT_LANGUAGE: "Язык по-умолчанию(на странице входа, при создании пользователя), варианты: en, ru",
            DATABASE_URL: "Строка подключения к базе, PDO (https://www.php.net/manual/ru/ref.pdo-mysql.connection.php)",
            DATABASE_NAME: "Имя базы данных",
            DATABASE_USER: "Имя пользователя",
            DATABASE_PASSWD: "Пароль",
            APP_NAME: "Имя приложения",
            API_KEY_EXPIRATION: "Время жизни API-ключа (в секундах)",
            TRUSTED_HOST_NETWORK_LIST: "Список доверенных сетей (используется в RPC и switcher-core), перечисляются через запятую",
            TRUSTED_HOST_PROXY_ENABLED: "Используется ли прокси (варианты: yes|no)",
            TRUSTED_HOST_PROXY_REAL_IP_HEADER: "Имя заголовка, с прокси-сервера для получения IP-адреса",
            AUTH_CHECK_PAIR_METHOD: "Имя класса, для проверки пары логин-пароль",
            RPC_CONCURRENCY: "Максимальное количество воркеров при выполнении json rpc batch",
            RPC_TIMEOUT: "Максимальное время ожидания ответа",
            RPC_RETRY: "Количество повторов в случае ошибки",
            RPC_URL: "Адрес RPC-сервера (не рекомендуется изменять при запуске через docker)",
            MEMCACHE_ENABLED: "Использовать memcached (варианты: yes|no)",
            MEMCACHE_SERVER: "Адрес сервера",
            MEMCACHE_PORT: "Порт сервера",
            MEMCACHE_CACHING_QUERY_TIMEOUT_SEC: "Время жизни кеша базы данных (в секундах)",
            SWC_TELNET_PORT: "Порт телнет",
            SWC_TELNET_TIMEOUT_SEC: "Максимальное время ожидания ответа от оборудования по telnet",
            SWC_SNMP_REPEATS: "Количество повторов по snmp",
            SWC_SNMP_TIMEOUT_SEC: "Максимальное время ожидания ответа по snmp",
            SWC_CALL_CONCURRENCY: "Максимальное количество одновременных запросов к оборудованию",
            SWC_MIKROTIK_API_PORT: "Порт API микротика",
            SWC_CACHE_SYSTEM: "Кеш-система для сохранения ответов от switcher-core",
            SWC_CACHE_ACTUALIZE_TIMEOUT_SEC: "Время актуализации данных (через сколько секунд заново запрашивать данные с оборудования), в секундах",
            SWC_CACHE_TIMEOUT_SEC: "Время жизни кеша, в секундах",
            ALL_OK_BILLING_DATABASE_URL: "Строка подключения к БД AllOkBilling, PDO",
            ALL_OK_BILLING_DATABASE_USER: "Имя пользователя",
            ALL_OK_BILLING_DATABASE_PASSWD: "Пароль",
            ALL_OK_BILLING_API_URL: "URL API AllOkBilling",
        },
    },
}